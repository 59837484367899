import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { IconText } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/IconText" title="IconText" mdxType="PageTitle" />
    <h2 {...{
      "id": "default-order"
    }}>{`Default order`}</h2>
    <Playground __position={1} __code={'<IconText icon={<TimesIcon scale=\"medium\" />} text=\"Hello world\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      IconText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconText icon={<TimesIcon scale="medium" mdxType="TimesIcon" />} text="Hello world" mdxType="IconText" />
    </Playground>
    <h2 {...{
      "id": "inline-reversed"
    }}>{`Inline reversed`}</h2>
    <Playground __position={2} __code={'<IconText\n  inline\n  reverse\n  icon={<TimesIcon scale=\"medium\" />}\n  text=\"Hello world\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      IconText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconText inline reverse icon={<TimesIcon scale="medium" mdxType="TimesIcon" />} text="Hello world" mdxType="IconText" />
    </Playground>
    <h2 {...{
      "id": "spaced-icon"
    }}>{`Spaced icon`}</h2>
    <Playground __position={3} __code={'<IconText\n  icon={<TimesIcon scale=\"medium\" />}\n  text={\n    <Rhythm ml={2}>\n      <span>Hello world</span>\n    </Rhythm>\n  }\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      IconText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconText icon={<TimesIcon scale="medium" mdxType="TimesIcon" />} text={<Rhythm ml={2} mdxType="Rhythm">
        <span>Hello world</span>
      </Rhythm>} mdxType="IconText" />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={IconText} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      